import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TagModification from "src/components/cockpit/blocks/tags/TagModification";
import TagsAttribution from "src/components/cockpit/blocks/tags/TagsAttribution";
import { useUserContext } from "src/contexts/UserContext";
import {
  Alert, Button, Drawer, Layout, Separator, Text,
} from "tarmac-v3";
import { useEmployeeListContext } from "src/contexts/EmployeeListContext";
import { getTagInfo } from "src/tools/UserHelpers";
import TagWithOptionalImages from "src/components/cockpit/employeeV3/blocks/TagWithOptionalImages";

const StyledDrawer = styled(Drawer)({
  "& .MuiStack-root": {
    height: "auto",
  },
});

const TaggingUserModal = (props) => {
  const {
    isOpen, setIsOpen, employee, handleClose, onSave,
  } = props;
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();
  const [errors, setErrors] = useState("");
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [tagInfo, setTagInfo] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { reloadEmployeesList } = useEmployeeListContext();
  const [errorExistingTag, setErrorExistingTag] = useState("");
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (employee) {
      setEmployeeInfo({ ...employee });
    }
  }, [employee]);

  const onSaveConfirmTagging = async () => {
    const tagIds = employeeInfo.tags.map((t) => t.id);

    const deletedTagsIds = employee.tags
      .filter((t) => !tagIds.includes(t.id))
      .map((t) => t.id);

    const employeePatch = { new_tags: tagIds, deleted_tags: deletedTagsIds };

    try {
      if (employeeInfo) {
        const updatedEmployee = await currentUser.api.modifyUser(
          employeeInfo.id,
          employeePatch,
        );

        setEmployeeInfo(updatedEmployee);
      }
      onSave(employee.id);
      await reloadEmployeesList();
      setSearchInput("");
      setErrorExistingTag("");
      setIsOpen(false);
    } catch (err) {
      setErrors(err.response.data.message);
    }
  };

  const closeDrawer = () => {
    setIsMenuOpen(false);
    setSearchInput("");
    setErrorExistingTag("");
    handleClose();
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onSaveConfirmModifying = async () => {
    try {
      if (Object.keys(tagInfo).length) await currentUser.api.updateTag(tagInfo.id, tagInfo);
      closeMenu();
    } catch (err) {
      setErrors(err.response.data.message);
    }
  };

  const actions = isMenuOpen ? (
    <>

      <Button
        variant="outlined"
        onClick={closeMenu}
        color="secondary"
        label={t("Annuler")}
        fullWidth
      />
      <Button
        variant="contained"
        onClick={onSaveConfirmModifying}
        color="darkGreen"
        label={t("Modifier le tag")}
        fullWidth
      />
    </>
  ) : (
    <>
      <Button
        variant="outlined"
        onClick={closeDrawer}
        color="secondary"
        label={t("Annuler")}
        fullWidth
      />
      <Button
        variant="contained"
        onClick={onSaveConfirmTagging}
        color="darkGreen"
        label={t("Enregistrer")}
        fullWidth
      />
    </>
  );

  const renderContent = () => {
    if (isMenuOpen) {
      return (
        <TagModification
          user={employeeInfo}
          closeMenu={closeMenu}
          getTagColor={() => tagInfo.color}
          tag={tagInfo}
          setTagInfo={setTagInfo}
        />
      );
    }

    const sirhTags = employeeInfo.tags?.filter((tag) => tag.tag_source === "HRMS") || [];
    const cockpitTags = employeeInfo.tags?.filter((tag) => !tag.is_sirh) || [];

    const sirhName = sirhTags.length > 0
      && sirhTags[0].hrms_name.charAt(0).toUpperCase() + sirhTags[0].hrms_name.slice(1).toLowerCase();

    return (
      <Layout gap={2}>
        {sirhTags.length > 0 && (
          <Layout direction="column" gap={2}>
            <Layout direction="column" gap={1}>
              <Text variant="body1">
                {t("Équipes")}
                {" "}
                {sirhName}
                :
              </Text>
              <Layout
                direction="row"
                spacing={1}
                flexWrap="wrap"
                data-cy="selectedTags"
              >
                {sirhTags?.map((tag) => {
                  const tagInfo = getTagInfo(tag);

                  return (
                    <TagWithOptionalImages
                      key={tag.id}
                      tag={{
                        ...tag,
                        avatar: tagInfo?.logo,
                      }}
                    />

                  );
                })}
              </Layout>
            </Layout>
            <Text variant="caption" color="secondary">{t("Les équipes {{sirhName}} ne sont pas modifiables dans votre Cockpit.", { sirhName })}</Text>
            <Separator orientation="horizontal" />
          </Layout>
        )}
        <Layout direction="column" gap={1}>
          <Text variant="body1">
            {t("Équipes Cockpit")}
          </Text>
          <TagsAttribution
            user={employeeInfo}
            error={errorExistingTag}
            setError={setErrorExistingTag}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            tags={cockpitTags}
            onChange={(tags) => {
              const updatedTags = [
                ...sirhTags,
                ...tags.filter((tag) => tag.tag_source !== "HRMS"),
              ];

              setEmployeeInfo({ ...employeeInfo, tags: updatedTags });
            }}
            setTagInfo={setTagInfo}
            toggleMenu={toggleMenu}
            isEditable
          />
        </Layout>

      </Layout>
    );
  };

  return (
    <StyledDrawer
      title={t("Assigner des équipes")}
      open={isOpen}
      onClose={closeDrawer}
      Actions={(
        <Layout direction="column" spacing={2} fullWidth>
          {!isMenuOpen && (
            <Alert severity="info">{t("Le changement régulier d'équipes peut avoir un impact sur le déploiement d'applications avec le MDM. Restez vigilant sur l'usage des équipes Cockpit.")}</Alert>
          )}
          <Layout direction="row" spacing={1} fullWidth>
            {actions}
          </Layout>
        </Layout>
      )}
      zIndex={10}
    >
      <Layout direction="column" spacing={1} justifyContent="space-between">
        {errors && <Alert onClick={() => setErrors("")}>{errors}</Alert>}
        {renderContent()}
      </Layout>
    </StyledDrawer>
  );
};

export default TaggingUserModal;

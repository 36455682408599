import React from "react";
import { Layout } from "tarmac-v3";
import Spinner from "./Spinner";

const MainLoader = () => (
  <Layout alignItems="center" justifyContent="center" sx={{ height: "100vh", width: "100%" }}>
    <Spinner />
  </Layout>
);

export default MainLoader;

import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ListCard,
} from "tarmac-v2";
import {
  Avatar,
  Icon,
  IconButton,
  Layout, TextField,
} from "tarmac-v3";

import { useConfirmModalContext } from "src/contexts/ConfirmModalContext";
import { useUserContext } from "src/contexts/UserContext";
import tagColorsAndNames from "src/data/tagColorsAndNames.json";

import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import TagsDeleteDialog from "src/components/dialogs/tags/TagsDeleteDialog";
import { useToastContext } from "src/contexts/ToastContext";
import styles from "./TagModification.module.scss";

const TagModification = (props) => {
  const {
    tag, setErr, closeMenu, err, setTagInfo, user,
  } = props;

  const { user: currentUser } = useUserContext();
  const { setConfirmModal } = useConfirmModalContext();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { addToast } = useToastContext();

  const colorForTags = tagColorsAndNames.filter((c) => !c.excluded_from_not_hrms_colorlist);

  const handleChange = (event) => {
    setTagInfo((prevInfo) => ({ ...prevInfo, label: event.target.value }));
  };

  const handleClick = (tagColor) => {
    setTagInfo((prevInfo) => ({ ...prevInfo, color: tagColor }));
  };

  const deleteTag = useCallback(async () => {
    await currentUser.api
      .deleteTag(tag.id);
    try {
      addToast(user ? t("L'équipe a bien été supprimée.") : t("Le tag a bien été supprimé."), "success");
      setTagInfo("");
      closeMenu();
      setConfirmModal(null);
    } catch (err) {
      setErr(err.response);
    }
  }, [currentUser.api, tag.id, addToast, user, t, setTagInfo, closeMenu, setConfirmModal, setErr]);

  const displayErrors = () => {
    if (err.response.request.status === 422) {
      return t(err);
    }

    return null;
  };

  return (
    <Layout spacing={1}>
      <TagsDeleteDialog
        user={user}
        open={open}
        setOpen={setOpen}
        deleteTag={deleteTag}
      />
      {t("Tags")}
      {err && (
        <ListCard className={`${styles.error} is-hidden-desktop`}>
          {displayErrors}
          <Icon icon={faTimes} className={styles.icon} onClick={() => setErr(null)} />
        </ListCard>
      )}
      <Layout direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <TextField
          className={styles.renameInput}
          value={tag.label}
          onChange={(e) => handleChange(e)}
          placeholder="Renommer le tag"
        />
        <IconButton size="large" icon={<Icon icon={faTrashCan} />} className={styles.icon} onClick={() => setOpen(true)} />
      </Layout>
      <Layout spacing={1}>
        {t("Couleur")}
        {colorForTags.map((c, index) => (
          <ListCard
            key={index}
            onClick={() => handleClick(c.value)}
            className={styles.colorCard}
            isActive={c.value === tag.color}
          >
            <Layout direction="row" spacing={2} alignItems="center">
              <Avatar
                icon={<Icon />}
                color={c.color}
                className={styles.avatar}
              />
              {t(c.french_name)}
            </Layout>
          </ListCard>
        ))}
      </Layout>
    </Layout>
  );
};

export default TagModification;

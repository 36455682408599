import React from "react";
import { Layout, Tag } from "tarmac-v3";

const TagSelect = ({
  tagsList,
  onSelectTag,
  toggleMenu,
  setTagInfo,
  isEditable,
}) => {
  const tagsListWithoutHRMS = tagsList.filter((tag) => tag.tag_source !== "HRMS");

  return (
    <Layout direction="row" spacing={1} flexWrap="wrap" data-cy="Tags">
      {tagsListWithoutHRMS.map((tag, index) => (
        <Tag
          key={index}
          label={tag.label}
          backgroundColor={tag.color}
          variant="filled"
          size="medium"
          onClick={() => onSelectTag(tag)}
          closeIcon={isEditable}
          onIconClick={() => {
            setTagInfo(tag);
            toggleMenu();
          }}
        />
      ))}
    </Layout>
  );
};

export default TagSelect;
